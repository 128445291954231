@import url("https://use.typekit.net/wyf5ryh.css");
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

main {
  display: block;
}

html {
  font-size: 80%;
  height: 100%;
  box-sizing: border-box;
  font-family: "effra", sans-serif;
}

*:focus {
  outline: none;
}

textarea:focus, input:focus {
  outline: none;
}

input[type=text], input[type=submit], input[type=email], input[type=tel],
input[type=url], input[type=search], textarea, select,
option {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
}

*, *:before, *:after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

/* Algemeen */
body {
  overflow-x: hidden;
  position: relative;
  max-width: 1400px;
  margin: 0 auto;
  background-color: #F2F8FC;
}

main {
  min-height: 100px;
}

/* Einde Algemeen */
/* Navigatie */
.navigation {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #000035;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 20;
}
.navigation a {
  text-decoration: none;
}
.navigation > i {
  color: white;
  font-size: 35px;
  cursor: pointer;
  transition: all 0.3s;
  padding: 15px 20px;
}
.navigation > i:hover {
  transform: scale(1.1);
}

.navbar {
  width: 100%;
  max-width: 800px;
  height: 100vh;
  background-color: #ffffff;
  position: fixed;
  left: -800px;
  z-index: 110;
  transition: all 0.5s ease-out;
  padding: 20px;
  top: 0;
  overflow: scroll;
}
.navbar > h2 {
  font-size: 20px;
  color: #000035;
  margin-top: 30px;
  font-weight: 700;
}
.navbar img {
  width: 60px;
}
.navbar a {
  width: 100%;
  display: flex;
  text-decoration: none;
  margin: 20px 0;
  transition: all 0.6s;
}
.navbar a:hover {
  transform: scale(1.07) translateX(10px);
}
.navbar a i {
  width: 40px;
  height: 40px;
  border-radius: 200px;
  border: 0.5px solid #f2f2f2;
  color: #252525;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
}
.navbar a div {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}
.navbar a div h2 {
  font-weight: 700;
  color: #252525;
  font-size: 16px;
}
.navbar a div h3 {
  font-weight: 400;
  font-size: 12px;
  color: #858585;
  margin-bottom: 5px;
}

.navbar.active {
  left: 0;
}

.navbar_top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: none;
  border-bottom: 0.5px solid #f2f2f2;
  padding-bottom: 30px;
}
.navbar_top i {
  cursor: pointer;
  font-size: 30px;
  padding: 15px 20px;
  margin-right: -20px;
}

.navbar .settings {
  padding-top: 30px;
  display: inline-block;
  width: 100%;
  margin-top: 10px;
  border-top: 1px solid #f2f2f2;
}
.navbar .settings a {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  text-decoration: none;
  color: #000035;
  margin-bottom: 15px;
}
.navbar .settings a i {
  width: 25px;
  height: 25px;
  transition: all 0.3s;
  border-radius: 200px;
  border: 2px solid #000035;
  color: #000035;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
}
.navbar .settings a:hover i {
  background-color: #000035;
  color: white;
}

/* Einde Navigatie */
/* Resorts */
.page_holder {
  background-color: #F2F8FC;
}

.resorts_holder {
  padding: 20px 10px;
  padding-bottom: 60px;
}
.resorts_holder h1 {
  font-size: 20px;
  font-weight: 700;
  color: #000035;
}
.resorts_holder h2 {
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-top: 15px;
  display: inline-block;
}
.resorts_holder article {
  background: #ffffff;
  border-radius: 10px;
  padding: 20px;
  margin: 20px 0;
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(210, 210, 210, 0.05);
  -moz-box-shadow: 0px 0px 5px 0px rgba(210, 210, 210, 0.05);
  box-shadow: 0px 0px 5px 0px rgba(210, 210, 210, 0.05);
}
.resorts_holder article h3 {
  font-size: 14px;
  color: #616368;
}
.resorts_holder article i {
  font-size: 30px;
  color: #d7d7d7;
}

.resorts_holder article.active i {
  color: #2ecc71;
}

.resorts_sort {
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 20px;
}
.resorts_sort i {
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 15px;
  top: 50%;
  color: #616368;
  border-radius: 4px 0 0 4px;
  transform: translateY(-50%);
}
.resorts_sort input {
  font-size: 16px;
  letter-spacing: 1px;
  border-radius: 4px;
  font-family: "effra", sans-serif;
  font-weight: 200;
  padding: 10px;
  border: #F1F1F1 2px solid;
  width: 100%;
}

.gotoNext {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 10px;
  padding: 15px 30px;
  width: 100%;
  max-width: 800px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 16px;
  font-weight: 200;
  border-radius: 40px;
  background-color: #000035;
  color: white;
}

/* Einde Resorts */
/* Wait Times */
.titleholder {
  width: 100%;
  margin-top: -53px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  z-index: 1;
  background-image: url(../assets/images/ttbg.png);
  background-size: contain;
  background-position: top;
  background-repeat: no-repeat;
  padding: 15px 10px;
}
.titleholder h2 {
  font-size: 11px;
  color: #9f9f9f;
  margin-top: 0;
  margin-bottom: 5px;
}

.waittimes_holder,
.openingtimes {
  padding: 20px 10px;
  padding-bottom: 80px;
  padding-top: 10px;
  min-height: 70vh;
}

.waittimes_holder h1,
.openingtimes h1 {
  font-size: 20px;
  font-weight: 700;
  color: #000035;
}

.waittimes_holder > div > h2 {
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-top: 20px;
  display: inline-block;
  width: 100%;
  margin-bottom: 10px;
}

.waittimes_holder .attractions,
.waittimes_holder .shows,
.waittimes_holder .restaurants {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.waittimes_holder article {
  margin: 5px 0.5%;
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  position: relative;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(210, 210, 210, 0.05);
  -moz-box-shadow: 0px 0px 5px 0px rgba(210, 210, 210, 0.05);
  box-shadow: 0px 0px 5px 0px rgba(210, 210, 210, 0.05);
  width: 49%;
}

.waittimes_holder .extras > div {
  border-top: 1px solid rgba(255, 255, 255, 0.4);
  margin-top: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.waittimes_holder .extras > div h3 {
  margin-bottom: 5px;
  font-weight: 400;
}
.waittimes_holder .extras > div h4 {
  font-weight: 200;
  line-height: 130%;
}
.waittimes_holder .extras > div div {
  width: 50%;
  padding-right: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.waittimes_holder .extras > div div:nth-of-type(2) {
  padding-left: 10px;
  border-left: 1px solid rgba(255, 255, 255, 0.4);
}

.waittimes_switch {
  width: 100%;
  margin: 0 auto;
  border-radius: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: #000035 1px solid;
  overflow: hidden;
  margin-top: 15px;
  max-width: 800px;
}
.waittimes_switch > div {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  transition: all 0.3s;
}
.waittimes_switch > div:not(:first-of-type) {
  border-left: 1px solid #000035;
}
.waittimes_switch div.active {
  background-color: #000035;
  color: white;
}

.attraction_content {
  width: 100%;
  position: relative;
  color: #fff;
  display: flex;
  justify-content: space-between;
  height: 100%;
}
.attraction_content h2 {
  font-size: 16px;
  font-weight: 700;
}
.attraction_content h3 {
  font-size: 13px;
  margin-top: 10px;
  font-weight: 200;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.attraction_content h3 div i {
  margin: 0 10px;
}
.attraction_content h2,
.attraction_content h3 {
  font-family: "effra", sans-serif;
  position: relative;
  z-index: 1;
}

.attraction_content .info {
  color: white;
  z-index: 1;
  position: relative;
  margin-top: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
}
.attraction_content .info div {
  font-size: 14px;
  line-height: 150%;
  width: 23%;
  margin: 1%;
  border-radius: 200px;
  border: 1px solid white;
  text-align: center;
}
.attraction_content .info div.passed {
  opacity: 0.3;
}

.attraction_times {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: "effra", sans-serif;
  text-transform: uppercase;
  text-align: center;
  border-radius: 4px;
  width: 70px;
}
.attraction_times h3 {
  font-size: 25px;
  width: 100%;
  margin-top: 0;
  font-weight: 700;
  justify-content: center;
  align-items: center;
}
.attraction_times p {
  font-size: 14px;
  width: 100%;
}
.attraction_times i {
  font-size: 25px;
}

.colorCode {
  padding: 20px;
  border-radius: 4px;
  width: calc(100% - 75px);
}

.open {
  background-color: #27ae60;
}

.openRedelijkDruk {
  background-color: #e67e22;
}

.openDruk {
  background-color: #f39c12;
}

.openHeelDruk {
  background-color: #d35400;
}

.openSuperDruk {
  background-color: #c03b2b;
}

.CLOSED {
  background-color: #95a5a6;
}

.DOWN {
  background-color: #e74c3c;
}

.SHOW {
  background-color: #89ACD0;
}

.park_switch {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #ffffff;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: #c6c6c6 2px solid;
}
.park_switch div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  width: 50%;
}
.park_switch div i {
  color: #9f9f9f;
  font-size: 16px;
  margin-bottom: 5px;
}
.park_switch div h2 {
  font-size: 14px;
  font-weight: 200;
  color: #9f9f9f;
}
.park_switch div.active i,
.park_switch div.active h2 {
  color: #000035;
}

.openingtimes {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.openingtimes h3 {
  font-size: 16px;
  font-weight: 700;
  width: 100%;
}
.openingtimes > article {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  background-color: #ffffff;
  padding: 25px;
  margin-top: 20px;
  border-radius: 10px;
  width: 49%;
}
.openingtimes > article div {
  width: 49%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 15px;
}
.openingtimes > article div:first-of-type {
  padding-right: 10px;
}
.openingtimes > article div:nth-of-type(2n) {
  border-left: 2px solid #f2f2f2;
  padding-left: 10px;
}
.openingtimes > article div h4 {
  font-size: 14 px;
}
.openingtimes > article div h4:last-of-type {
  font-weight: 200;
  margin-top: 5px;
}

/* Einde Wait Times */
/* Responsive */
@media only screen and (max-width: 1200px) {
  .waittimes_holder article {
    width: 100%;
    margin: 5px 0;
  }
}
@media only screen and (max-width: 550px) {
  .openingtimes > article {
    width: 100%;
  }
}
/* Einde Wait Times */

